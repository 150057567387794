import { accountTypes } from 'components/Modals/CreateUser/config'
import { InputType } from 'config'
import { timesOption } from 'pages/LoanSubmission/NotifyBorrower'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'
import { loanTypeMap } from 'reducers/loanDetail.reducer'

export const getRulesConfigurationFields = (loanGlobalStatus: any) => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Email Rules',
      span: 'full',
    },
    emailWithdrawnLoans: {
      inputType: 'multiselect',
      title: 'Email Receivers of Withdrawn/Declined Loans',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailSurveyNotification: {
      inputType: 'multiselect',
      title: 'Email Receivers of Survey Notification',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailWeeklyReport: {
      inputType: 'multiselect',
      title: 'Email Receivers of Weekly Loan Registration Report',
      options: TaskAssigneeOptions,
      defaultSelected: false,
      required: true,
    },
    section2: {
      inputType: 'section',
      title: 'Task Generation',
      span: 'full',
    },
    revalidateLoan: {
      inputType: 'multiselect',
      title: 'HUD1 Change - Loan Revalidation',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    section3: {
      inputType: 'section',
      title: 'Borrower Auto Notification',
      span: 'full',
    },
    borrowerAutoNotifyLoanStatus: {
      inputType: 'multiselect',
      title: 'Loan Status',
      options: loanGlobalStatus,
      defaultSelected: false,
    },
    borrowerAutoNotifyLoanTypes: {
      inputType: 'multiselect',
      title: 'Loan Channel',
      options: loanTypeMap,
      defaultSelected: false,
    },
    borrowerAutoNotifyFrequency: {
      inputType: 'text',
      type: 'number',
      title: 'Days Reminder Frequency',
    },
    borrowerAutoNotifyTime: {
      inputType: 'select',
      title: 'Time',
      options: timesOption,
      allowDefaultOption: false,
      hasDefaultOption: true,
    },
  }

  return rlt
}
