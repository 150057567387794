import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config/input.type.constants'
import { taskInputs } from 'pages/Admin/ConditionsAndTemplates'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { submitTask } from 'services'
import { Button, FormTable } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { LoanSubmissionConfig } from './types'

export const LoanSubmissionDetails = ({
  action,
  label,
  data,
  adminEmails,
  onBack,
  tempTasks,
}: {
  action: string
  label: string
  data: LoanSubmissionConfig
  adminEmails: string[]
  onBack: Function
  tempTasks: Array<any>
}) => {
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])
  const [tasks, setTasks] = useState(Array<any>)
  const [inputs, setInputs] = useState(defaultInputs(loanGlobalStatus))
  const [tInputs, setTInputs] = useState<Record<string, any>>({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setTasks(data.tasks)
  }, [data])

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      const value = (data as any)[key]
      newInputs[key].value = value
    })
    setInputs(newInputs)
  }, [action])

  const assigneeOptions = useMemo(() => {
    const options = { ...TaskAssigneeOptions }
    adminEmails.forEach((email) => (options[email] = email))
    return options
  }, [adminEmails])

  useEffect(() => {
    let obj: any = {}
    tempTasks.map((task) => {
      obj[`${task.no}`] = `${task.no} - ${task.description}`
    })
    let temp: any = taskInputs()
    temp = {
      taskTemplateNo: {
        title: 'Choose Template',
        inputType: 'select',
        hasDefaultOption: true,
        options: obj,
        error: '',
      },
      ...temp,
    }
    temp['assignedTo'].options = assigneeOptions
    temp['assignedToManager'].options = assigneeOptions
    setTInputs(temp)
  }, [tempTasks, assigneeOptions])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      let { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return false
    values.tasks = tasks
    onBack(values)
  }

  const onSubmitTasks = (currentId: any, values: Record<string, any>) => {
    return new Promise(async (resolve) => {
      let temp = cloneDeep(tasks)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        if (values.taskTemplateNo) {
          temp.push(values)
        } else {
          setIsLoading(true)
          delete values.taskTemplateNo
          try {
            const res = await submitTask(0, values)
            setIsLoading(false)
            if (res.taskNo) {
              values.taskTemplateNo = res.taskNo
              temp.push(values)
            }
          } catch {
            setIsLoading(false)
          }
        }
      } else {
        temp.map((item, index) => {
          if (item.id == currentId) temp[index] = { ...temp[index], ...values }
        })
      }
      setTasks(temp)
      resolve(values)
    })
  }

  const onRemoveTasks = async (id: any) => {
    return new Promise(async (resolve) => {
      let temp: any = []
      tasks.map((item) => {
        if (item.id != id) temp.push(item)
      })
      setTasks(temp)
      resolve(true)
    })
  }

  const inputLogic = (inputs: Record<string, InputType>, ID?: number, key?: string) => {
    console.log(ID)
    const templateNo = inputs['taskTemplateNo'].value
    inputs['description'].disabled = templateNo ? true : false
    if (key === 'taskTemplateNo') {
      let temp: any = taskInputs()
      Object.keys(temp).map((key) => {
        inputs[key].value = temp[key].value
      })

      tempTasks.map((item) => {
        if (item.no == templateNo) {
          Object.keys(inputs).map((key) => {
            if (key !== 'taskTemplateNo') {
              inputs[key].value = item[key] || temp[key].value
              inputs[key].error = ''
            }
          })
        }
      })
    }
    return inputs
  }

  return (
    <div className="w-full relative">
      <LayoutLoading show={isLoading} />
      <div className="text-shade-blue flex items-center mb-2 font-bold">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="hover:underline cursor-pointer" onClick={() => onBack(null)}>
          Back
        </span>
      </div>

      <div className="flex flex-col gap-4 mb-4">
        <p className="text-[18px] font-semibold">Action: {label}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key) => {
            const input = inputs[key]

            if (key == 'taskAssignee') {
              ;(input as any).options = assigneeOptions
            }

            return (
              <div className={input.span ? `col-span-${input.span}` : ''} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
        <div>
          <FormTable
            key="tasks"
            header={[
              { key: 'taskTemplateNo', title: 'Template No' },
              {
                key: 'description',
                title: (
                  <div>
                    <div className="border-b mb-1">Description</div>Notes
                  </div>
                ),
                value: (row: any) => {
                  return (
                    <div>
                      <div className="border-b mb-1">{row.description}</div>
                      <div>{row.notes}</div>
                    </div>
                  )
                },
              },
              {
                key: 'assignedTo',
                title: (
                  <div>
                    <div className="border-b mb-1">Assigned to</div>Manager
                  </div>
                ),
                value: (row: any) => {
                  return (
                    <div>
                      <div className="border-b mb-1">
                        {row.assignedTo.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}
                      </div>
                      <div>{row.assignedToManager.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}</div>
                    </div>
                  )
                },
              },
              { key: 'dueDays', title: 'Due Days' },
            ]}
            inputs={tInputs}
            inputLogic={inputLogic}
            data={tasks}
            onSubmit={(id, values) => onSubmitTasks(id, values)}
            onRemove={(id) => onRemoveTasks(id)}
          />
        </div>
      </div>

      <Button onClick={onSubmit}>Save</Button>
    </div>
  )
}
