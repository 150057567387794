export interface IBorrowerLoanWorkflowBase {
  title: string
  visibleLogic?: IVisibilityOption[][]
}

export enum FieldOrigin {
  LoanStructure = 'LoanStructure',
  BorrowerInformation = 'BorrowerInformation',
  PropertyInformation = 'PropertyInformation',
  AssetLiability = 'AssetLiability',
  TrackRecord = 'TrackRecord',
  DeclarationsHMDA = 'DeclarationsHMDA',
}

export const fieldOriginOptions: Record<FieldOrigin, string> = {
  [FieldOrigin.LoanStructure]: 'Loan Structure',
  [FieldOrigin.BorrowerInformation]: 'Borrower Information',
  [FieldOrigin.PropertyInformation]: 'Property Information',
  [FieldOrigin.AssetLiability]: 'Asset & Liability',
  [FieldOrigin.TrackRecord]: 'Track Record',
  [FieldOrigin.DeclarationsHMDA]: 'Declarations & HMDA',
}

export interface IBorrowerLoanWorkflowSelect extends IBorrowerLoanWorkflowInput {
  type: 'select'
  options: Record<string, string>
  visibility: Record<string, boolean> // Visibility for each options
}

export interface IVisibilityOption {
  fieldOrigin: FieldOrigin
  fieldKey: string
  value: string | number | boolean
}

export interface IBorrowerLoanWorkflowInput extends IBorrowerLoanWorkflowBase {
  fieldOrigin: FieldOrigin
  fieldKey: string
}

export type IBorrowerLoanWorkflowControl = IBorrowerLoanWorkflowSelect | IBorrowerLoanWorkflowInput

export interface IBorrowerLoanWorkflowForm extends IBorrowerLoanWorkflowBase {
  type: 'form'
  inputs: IBorrowerLoanWorkflowControl[]
}

export type IBorrowerLoanWorkflowStep = IBorrowerLoanWorkflowSelect | IBorrowerLoanWorkflowForm
