import type { ILoanState } from 'reducers/loan.reducer'

import {
  LOAN_SET_ALL_DATA,
  LOAN_SET_DATA,
  LOAN_SET_GLOBAL_STATUS,
  LOAN_SET_GROUP_DATA,
  SET_LOAN_LTV_MAX_LIMIT,
  SET_LOAN_TIER_LTV_MAX_LIMIT,
} from './types'

export function setLoanLtvMaxLimit(data: any) {
  return { type: SET_LOAN_LTV_MAX_LIMIT, data }
}

export function setLoanTierLtvMaxLimit(data: any) {
  return { type: SET_LOAN_TIER_LTV_MAX_LIMIT, data }
}

export function setLoanData(data: { key: string; data: any }) {
  return { type: LOAN_SET_DATA, key: data.key, data: data.data }
}

export function setLoanAllData(data: ILoanState) {
  return { type: LOAN_SET_ALL_DATA, data }
}

export function setLoanGroupData(data: ILoanState) {
  return { type: LOAN_SET_GROUP_DATA, data }
}

export function setLoanGlobalStatus(data: ILoanState) {
  return { type: LOAN_SET_GLOBAL_STATUS, data }
}
