import { type InputType } from 'config'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'

export const defaultInputs = (loanGlobalStatus: any): Record<string, InputType> => {
  return {
    taskAssignee: {
      inputType: 'multiselect',
      title: 'Task Assignee',
      options: TaskAssigneeOptions,
      defaultSelected: false,
      required: true,
    },
    section1: {
      inputType: 'section',
      title: 'Loan Status',
      span: 'full',
    },
    statusTo: {
      inputType: 'select',
      title: 'Status Change To',
      options: loanGlobalStatus,
      hasDefaultOption: true,
      defaultOptionText: '- No Change -',
      required: false,
    },
    statusToRequireVerify: {
      inputType: 'check',
      title: 'Require Validation',
      tooltip:
        'Check all statuses of Loan (depending on the status):<br/>expiration dates, whether all conditions are cleared',
      required: true,
    },
    statusListBeforeToUpdate: {
      inputType: 'multiselect',
      title: `Update Loan status when below`,
      options: loanGlobalStatus,
      tooltip: 'Update Loan Status when is in the status list',
      defaultSelected: false,
      required: false,
    },
    section2: {
      inputType: 'section',
      title: 'Email & Task',
      span: 'full',
    },
    email: {
      inputType: 'togglebutton',
      title: 'Email',
      required: true,
    },
    task: {
      inputType: 'togglebutton',
      title: 'Task',
      required: true,
    },
    taskOnTask: {
      inputType: 'togglebutton',
      title: 'Task on Task',
      required: true,
    },
    section3: {
      inputType: 'section',
      title: 'Chain of Tasks',
      span: 'full',
    },
  }
}
