import { ChevronLeftIcon, ChevronRightIcon, XCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { PaymentType } from 'pages/Admin'
import { StripeWithMethod } from 'pages/Payment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  findStripeIntents,
  getAllPaymentType,
  getPaymentData,
  IStripeIntent,
  isVendorEnabled,
  PaymentOrderType,
  sendLoanProcRushPaymentData,
  StripeIntentPaidStatus,
  StripeIntentStatus,
  StripeMethod,
} from 'services'
import { Button, Modal, PlainTable, RadioGroup } from 'stories/components'
import { formatTime, getPrice2decimal, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { LoanProcessRushPaymentRequestTypeTitles } from './constants'

export const requestTypeTitles: Record<string, string> = {
  // entire: 'Rush File Request',
  // appraisal: 'Transferred Appraisal Request',
  // both: 'Rush File Request + Transferred Request',
  // diligence: 'Commitment Fee',
  // creditReport: 'Credit Report Fee',
  // commitmentCreditReport: 'Commitment Fee + Credit Report Fee',
  custom: 'Other Fee',
}

export const requestTypeFees: Record<string, number> = {
  entire: 500,
  appraisal: 375,
  both: 875,
  diligence: 500,
  creditReport: 50,
  commitmentCreditReport: 550,
  custom: 0,
}

export const RushSubmitModal = ({
  showDescription = true,
  onClose,
}: {
  showDescription?: boolean
  onClose: Function
}) => {
  const { borrower, profile } = useSelector((state: any) => {
    return {
      borrower: state.borrower.borrower,
      profile: state.auth.profile,
    }
  })

  const [loading, setLoading] = useState(false)
  const [requestType, setRequestType] = useState('')
  const [borrowerEmailInput, setBorrowerEmailInput] = useState<InputType>({
    inputType: 'text',
    type: 'email',
    title: 'Borrower Email',
    value: borrower.email,
    required: true,
    visible: true,
  })
  const [customFeeInput, setCustomFeeInput] = useState<InputType>({
    inputType: 'text',
    type: 'thousandSep',
    title: 'Fee Amount',
    prefix: '$',
    value: '',
    required: true,
    visible: false,
  })
  const [feeTitleInput, setFeeTitleInput] = useState<InputType>({
    inputType: 'text',
    title: 'Title of Fee',
    value: '',
    required: true,
    visible: false,
  })
  const [showPayForm, setShowPayForm] = useState(false)
  const [status, setStatus] = useState<StripeIntentStatus>()
  const [message, setMessage] = useState('')
  const [isCompleted, setCompleted] = useState(false)
  const [rushId, setRushId] = useState(0)
  const [pendingPayments, setPendingPayments] = useState<IStripeIntent[]>([])
  const [paymentType, setPaymentType] = useState<PaymentType[] | null>(null)
  const [enabled, setEnabled] = useState(false)
  const loanNumber = setLoanNumber()

  const getNewPaymentType = () => {
    getAllPaymentType().then((data) => {
      setPaymentType(data)
    })
  }

  useEffect(() => {
    getNewPaymentType()
    setLoading(true)
    const rushId = Date.now()
    setRushId(rushId)

    isVendorEnabled('Stripe').then((data) => setEnabled(data))

    getPaymentData({ orderType: PaymentOrderType.LoanSubmissionRush, refId: `${loanNumber}-${rushId}` })
      .then(({ requestType, status, message, customFee, feeTitle }) => {
        setRequestType(requestType)
        setStatus(status)
        setMessage(message)
        const isCompleted = StripeIntentPaidStatus.includes(status)
        setCompleted(isCompleted)
        // if (isCompleted) setShowPayForm(true)

        const newInput = cloneDeep(customFeeInput)
        newInput.visible = requestType == 'custom'
        if (newInput.visible) newInput.value = customFee
        setCustomFeeInput(newInput)

        const newTitleInput = cloneDeep(feeTitleInput)
        newTitleInput.visible = requestType == 'custom'
        if (newTitleInput.visible) newTitleInput.value = feeTitle
        setFeeTitleInput(newTitleInput)
      })
      .finally(() => setLoading(false))

    findStripeIntents({
      status: StripeIntentStatus.processing,
      orderType: PaymentOrderType.LoanSubmissionRush,
      loanNumber,
    }).then((data) => setPendingPayments(data))
  }, [])

  const isPayable = useMemo(() => {
    return requestType != 'custom' || (!!removeComma(customFeeInput.value) && !!feeTitleInput.value)
  }, [requestType, customFeeInput, feeTitleInput])

  const onChangeRequestType = (value: string) => {
    setRequestType(value)

    const newInput = cloneDeep(customFeeInput)
    newInput.visible = value == 'custom'
    setCustomFeeInput(newInput)

    const newTitleInput = cloneDeep(feeTitleInput)
    newTitleInput.visible = value == 'custom'
    setFeeTitleInput(newTitleInput)

    if (value == 'none') onClose(false)
  }

  const onChangeCustomFee = (key: string, value: string) => {
    const newInput = cloneDeep(customFeeInput)
    newInput.value = value
    newInput.error = InputValidate(newInput)
    setCustomFeeInput(newInput)
  }

  const onChangeFeeTitle = (key: string, value: string) => {
    const newTitleInput = cloneDeep(feeTitleInput)
    newTitleInput.value = value
    newTitleInput.error = InputValidate(newTitleInput)
    setFeeTitleInput(newTitleInput)
  }

  const onChangeBorrowerEmail = (key: string, value: string) => {
    const newInput = cloneDeep(borrowerEmailInput)
    newInput.value = value
    newInput.error = InputValidate(newInput)
    setBorrowerEmailInput(newInput)
  }

  const onSendLinkToBorrower = () => {
    setLoading(true)

    let feeAmount: number | string = 0
    let feeTitle: string = ''

    if (paymentType?.length && requestType !== 'custom') {
      const paymentTypeItem = paymentType.find((item) => item.id.toString() === requestType)
      if (paymentTypeItem) {
        feeAmount = paymentTypeItem.cost
        feeTitle = paymentTypeItem.title
      }
    } else if (requestType === 'custom') {
      feeAmount = removeComma(customFeeInput.value)
      feeTitle = feeTitleInput.value || 'Custom Fee'
    }

    sendLoanProcRushPaymentData({
      id: rushId,
      requestType: 'custom',
      customFee: feeAmount,
      feeTitle,
      method: 'link',
      borrowerEmail: borrowerEmailInput.value,
    })
      .then(() => {
        toast('Email with payment link is sent successfully.', { type: 'info' })
      })
      .finally(() => {
        setLoading(false)
        setRushId(Date.now())
      })
  }

  const onPayNow = () => {
    setShowPayForm(true)
  }

  const onPayCompleted = async (intentId: string, status: any) => {
    setStatus(status)
    setCompleted(StripeIntentPaidStatus.includes(status))

    setLoading(true)
    const customFee =
      requestType === 'custom'
        ? removeComma(customFeeInput.value)
        : paymentType?.find((pt) => pt.id.toString() === requestType)?.cost || requestTypeFees[requestType]

    sendLoanProcRushPaymentData({
      id: rushId,
      requestType: 'custom',
      customFee,
      feeTitle: feeTitleInput.value,
      method: 'pay',
      intentId,
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      title="Invoice"
      titleOkay=""
      onClose={() => onClose(status == StripeIntentStatus.succeeded && ['entire', 'both'].includes(requestType))}
      isOpen={true}
      disabled={!requestType}
      hiddenX
    >
      <div className="w-196">
        <LayoutLoading show={loading} />
        {!enabled ? (
          <p className="mb-1">Please contact to Administrator to enable Payment</p>
        ) : !showPayForm ? (
          <>
            {status &&
              [StripeIntentStatus.payment_failed, StripeIntentStatus.requires_payment_method].includes(status) &&
              !!message && (
                <div
                  className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] flex items-center"
                  role="alert"
                >
                  <XCircleIcon className="w-6 h-6"></XCircleIcon>
                  <span className="ml-1">{message}</span>
                </div>
              )}
            {showDescription && (
              <p
                className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded text-[15px] mb-6"
                role="alert"
              >
                Once you submit this request, our team will prioritize the processing of this file to ensure a swift
                turnaround.
              </p>
            )}

            {!!pendingPayments.length && (
              <>
                <RenderInput
                  input={{
                    inputType: 'section',
                    title: 'Pending Payments',
                  }}
                  Key="section2"
                  onChange={() => {}}
                />
                <PlainTable
                  thClass="!px-2"
                  tdClass="!px-2 py-3"
                  widthClass="my-4 w-full"
                  header={['No', 'Request Type', 'Amount', 'Status', 'Email', 'Created At']}
                  data={pendingPayments.map((intent, index) => [
                    index + 1,
                    <p className="max-w-[10rem]">
                      {LoanProcessRushPaymentRequestTypeTitles[intent.data.requestType] || intent.data.feeTitle}
                    </p>,
                    `$${getPrice2decimal(intent.amount, false, true)}`,
                    <p className="capitalize">{intent.status}</p>,
                    intent.email,
                    formatTime(intent.createdAt),
                  ])}
                />
              </>
            )}

            <RadioGroup
              id="requestType"
              title="Request Type"
              options={Object.fromEntries(
                [
                  ...Object.entries(requestTypeTitles).map(([key, title]) => [
                    key,
                    `${title} - $${getPrice2decimal(requestTypeFees[key], false, true)}`,
                  ]),
                  ...(paymentType || []).map((item) => [
                    item.id.toString(),
                    `${item.title} - $${getPrice2decimal(item.cost, false, true)}`,
                  ]),
                ]
                  .filter(([key]) => key !== 'custom')
                  .concat(
                    (paymentType || [])
                      .map((item) => [
                        item.id.toString(),
                        `${item.title} - $${getPrice2decimal(item.cost, false, true)}`,
                      ])
                      .concat([
                        [
                          'custom',
                          `${requestTypeTitles['custom']} - $${getPrice2decimal(
                            requestTypeFees['custom'],
                            false,
                            true,
                          )}`,
                        ],
                      ]),
                  ),
              )}
              value={requestType}
              onChange={onChangeRequestType}
            />
            {customFeeInput.visible && (
              <div className="grid grid-cols-2 my-4 gap-4 items-center">
                <RenderInput Key="customFeeInput" input={customFeeInput} onChange={onChangeCustomFee} />
                <RenderInput Key="feeTitleInput" input={feeTitleInput} onChange={onChangeFeeTitle} />
              </div>
            )}

            {!!requestType && (
              <>
                <RenderInput
                  input={{
                    inputType: 'section',
                    title: 'Borrower will pay',
                  }}
                  Key="section1"
                  onChange={() => {}}
                />
                <div className="grid grid-cols-2 my-4 gap-4 items-center">
                  <RenderInput Key="borrowerEmail" input={borrowerEmailInput} onChange={onChangeBorrowerEmail} />
                  <Button
                    disabled={!borrowerEmailInput.value || !!borrowerEmailInput.error || !isPayable}
                    link
                    color="blue"
                    onClick={onSendLinkToBorrower}
                  >
                    <span className="flex gap-2 items-center justify-center">
                      Send Payment Link
                      <ChevronRightIcon className="w-4 h-4" />
                    </span>
                  </Button>
                </div>

                <RenderInput
                  input={{
                    inputType: 'section',
                    title: 'Pay Now',
                  }}
                  Key="section2"
                  onChange={() => {}}
                />
                <div className="grid grid-cols-2 my-4 gap-4 items-center">
                  <Button onClick={onPayNow} disabled={!isPayable}>
                    Pay Now
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {!isCompleted ? (
              <div className="text-shade-blue flex items-center mb-2 font-bold">
                <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
                <span className="hover:underline cursor-pointer" onClick={() => setShowPayForm(false)}>
                  Back
                </span>
              </div>
            ) : (
              <></>
            )}

            <StripeWithMethod
              availableMethods={[StripeMethod.card]}
              hasAdditionalBankMethod
              paymentDetails={{ status }}
              onCompleted={onPayCompleted}
              paymentData={{
                rushId,
                email: profile.email,
                amount:
                  requestType === 'custom'
                    ? removeComma(customFeeInput.value)
                    : paymentType?.find((pt) => pt.id.toString() === requestType)?.cost || requestTypeFees[requestType],
                feeTitle:
                  requestType === 'custom'
                    ? feeTitleInput.value || 'Custom Fee'
                    : paymentType?.find((pt) => pt.id.toString() === requestType)?.title || '',
                refId: `${loanNumber}-${rushId}`,
                orderType: PaymentOrderType.LoanSubmissionRush,
                loanNumber,
                requestType: requestType === 'custom' || !(requestType in requestTypeTitles) ? 'custom' : requestType,
                method: 'pay',
              }}
            />
          </>
        )}
      </div>
    </Modal>
  )
}
