import { getInitialLoanFields, InputType } from 'config'
import { defaultInputs as defaultBorrowerInformationInputs } from 'pages/LoanApplication/BorrowerInformation/constants'
import { defaultInputs as defaultDeclarationsHmdaInputs } from 'pages/LoanApplication/DeclarationsHmda/constants'
import { defaultInputs as defaultPropertyInformationInputs } from 'pages/LoanApplication/PropertyInformation/constants'
import { capitalizeFirstLetter } from 'utils'

import { ignoredFields } from './config'
import { FieldOrigin } from './types'

export const getInputFields = (): Record<FieldOrigin, Record<string, InputType>> => {
  return {
    [FieldOrigin.LoanStructure]: getInitialLoanFields(),
    [FieldOrigin.BorrowerInformation]: defaultBorrowerInformationInputs(),
    [FieldOrigin.PropertyInformation]: defaultPropertyInformationInputs(),
    [FieldOrigin.AssetLiability]: {},
    [FieldOrigin.TrackRecord]: {},
    [FieldOrigin.DeclarationsHMDA]: defaultDeclarationsHmdaInputs().inputs,
  }
}

export const inputOptions = (inputType = '') => {
  const inputFields = getInputFields()
  const options: Record<FieldOrigin, Record<string, string>> = {} as any
  Object.keys(inputFields).forEach((_fieldOrigin) => {
    const fieldOrigin = _fieldOrigin as any as FieldOrigin
    const inputs = inputFields[fieldOrigin]
    options[fieldOrigin] = {}

    Object.keys(inputs).forEach((key) => {
      const input = inputs[key]
      if (['section'].includes(input.inputType)) return
      const isIgnoredField = ignoredFields.findIndex((v) => v.fieldOrigin == fieldOrigin && v.fieldKey == key) != -1
      if (isIgnoredField) return
      if (inputType && input.inputType.toLowerCase() != inputType.toLowerCase()) return

      if (inputType) options[fieldOrigin][key] = input.title
      else options[fieldOrigin][key] = `[${capitalizeFirstLetter(input.inputType)}] ${input.title}`
    })
  })

  return options
}
