import { setLoanGlobalStatus } from 'actions/loan.action'
import { LayoutLoading } from 'components/LayoutLoading'
import { BaseLayout } from 'layouts'
import { Suspense, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useDispatch } from 'react-redux'
import { Switch } from 'react-router-dom'
import { BaseRoute, TemporaryRoute } from 'routes'
import { getSetting } from 'services/apis'
const queryClient = new QueryClient()
function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    getSetting('LOAN_STATUS_LABEL').then(({ value }) => {
      const content = JSON.parse(value || '{}')
      let obj: any = {}
      Object.keys(content).map((key) => {
        obj[key] = content[key].new
      })
      dispatch(setLoanGlobalStatus(obj))
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LayoutLoading show={true} />}>
        <Switch>
          {TemporaryRoute}
          <BaseLayout>
            <BaseRoute />
          </BaseLayout>
        </Switch>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
