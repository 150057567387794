import cloneDeep from 'clone-deep'
import { useState } from 'react'
import { RenderInput } from 'utils/RenderInput'

const termsAndConditions = [
  'I have received, reviewed, and accepted all lending disclosures including the Loan Estimate. I understand that I am not required to complete this agreement merely because I have received these disclosures and/or signed a loan application.',
  'I understand that by filling in the requested information below that I am acknowledging I am the borrower, and I agree to the terms below. I understand the (insert fee here)fee is nonrefundable in whole once the (insert fee here) fee has been processed.',
  'I understand that I am authorizing (Insert Lender Name) to run the credit card information supplied for the indicated amount for services ordered. I understand that regardless of loan status, the charge is NON-REFUNDABLE under all circumstances.',
  'By digitally checking the box, I am in agreeance with all of the terms and conditions outlined above and I am ready to proceed with payment.',
]
export const PaymentTermsConditions = ({ onChangeTerms }: { onChangeTerms: Function }) => {
  const [terms, setTerms] = useState<Array<boolean>>([])

  const onChange = (index: number, value: any) => {
    const temp = cloneDeep(terms)
    temp[index] = value
    let isAgreed = true
    for (let i = 0; i < termsAndConditions.length; i++) {
      if (!temp[i]) {
        isAgreed = false
        break
      }
    }
    if (isAgreed) onChangeTerms(true)
    else onChangeTerms(false)
    setTerms(temp)
  }
  return (
    <div>
      <RenderInput
        input={{ inputType: 'group', title: 'Payment Terms and Conditions' }}
        Key="title"
        onChange={onChange}
      />
      {termsAndConditions.map((v, index: number) => {
        return (
          <div key={v} className="input mt-2 flex gap-4">
            <div>
              <RenderInput
                input={{ inputType: 'checkbox', title: '', value: terms[index] || false }}
                Key={v}
                onChange={(key: string, value: any) => onChange(index, value)}
              />
            </div>
            <p className="flex-1 cursor-pointer" onClick={() => onChange(index, !terms[index])}>
              {v}
            </p>
          </div>
        )
      })}
    </div>
  )
}
